import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import ElementUI from "element-ui";

import wx from "weixin-js-sdk";

Vue.prototype.$wx = wx;

// 引入rem
import "./assets/js/mobleFontSet.js";

// 引入css
import "./assets/css/reset.css";
import "element-ui/lib/theme-chalk/index.css";
import "./assets/css/style_theme/theme/index.css";
import "./assets/css/style.css";

Vue.use(ElementUI);

Vue.use(require("vue-wechat-title"));

import axios from "axios";

Vue.prototype.$http = axios;
// Vue.prototype.apiUrl = "http://192.168.31.16:21011";
// Vue.prototype.apiUrl = "http://api.ghatg.xiaoshuzn.com";
Vue.prototype.apiUrl = "https://api.zq.ghatg.com";

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
