<template>
  <div class="detail bgFFF memberMM">
    <!-- <div v-wechat-title="cont.title"></div> -->
    <div class="detailHd">
      <div class="detailHdTit">
        {{ cont.title }}
      </div>
      <div class="detailHdCont">
        <span
          >浏览次数：<label>{{ cont.view_number }}</label></span
        >
        <span
          >发布时间：<label>{{ cont.update_time.slice(0, 10) }}</label></span
        >
      </div>
    </div>
    <div class="detailWrap" v-html="cont.content"></div>
  </div>
</template>

<script>
var ua = window.navigator.userAgent.toLowerCase();
function isWeiXin() {
  return ua.match(/micromessenger/i) == "micromessenger";
}
function isComWeiXin() {
  return (
    ua.match(/micromessenger/i) == "micromessenger" &&
    ua.match(/wxwork/i) == "wxwork"
  );
}

export default {
  data() {
    return {
      cont: {
        update_time: "",
      },
    };
  },
  mounted() {
    this.getData();
    // eslint-disable-next-line no-undef
    console.log(this.$wx)
  },
  methods: {
    async getData() {
      var _this = this;
      var id = _this.$route.query.id;
      var { data } = await _this.$http.post(
        _this.apiUrl + "/news/getNewsDetail",
        { id: id, url: window.location.href }
      );

      _this.cont = data.data;
      document.title = data.data.title;
      var url = data.wxConfig.url;
      delete data.wxConfig.url;
      if (isComWeiXin()) {
        this.comWeixin(data, url, id);
      } else if (isWeiXin()) {
        this.weixin(data, url, id);
      }
    },
    comWeixin(data, url, id) {
      // eslint-disable-next-line no-undef
      let wx = window.jweixin;
      // eslint-disable-next-line no-undef
      wx.config({
        beta: true, // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: data.qywxConfig.appId, // 必填，企业微信的corpID
        timestamp: data.qywxConfig.timestamp + "", // 必填，生成签名的时间戳
        nonceStr: data.qywxConfig.nonceStr, // 必填，生成签名的随机串
        signature: data.qywxConfig.signature, // 必填，签名，见 附录-JS-SDK使用权限签名算法
        jsApiList: data.qywxConfig.jsApiList, // 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来
        url: url,
      });

      wx.error(function (res) {
        console.log("------------------------");
        console.log(res);
        console.log("------------------------");
        // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
      });

      // eslint-disable-next-line no-undef
      wx.ready(() => {
        // eslint-disable-next-line no-undef
        wx.onMenuShareAppMessage({
          title: data.data.title, // 分享标题
          desc: this.setText(data.data.content), // 分享描述
          link: `https://m.ghatg.com/detail?id=${id}`, // 分享链接；在微信上分享时，该链接的域名必须与企业某个应用的可信域名一致
          imgUrl: data.data.img
            ? data.data.img
            : "https://api.zq.ghatg.com/images/1677749724070.png", // 分享图标
          // enableIdTrans: 1, // 是否开启id转译，不填默认为0
          success: function () {
            // 用户确认分享后执行的回调函数
          },
          error: function (res) {
            if (res.errMsg.indexOf("no permission") > 0) {
              alert("未agentConfig");
            }
          },
          cancel: function () {
            // 用户取消分享后执行的回调函数
          },
        });

        // eslint-disable-next-line no-undef
        wx.onMenuShareWechat({
          title: data.data.title, // 分享标题
          desc: this.setText(data.data.content), // 分享描述
          link: `https://m.ghatg.com/detail?id=${id}`, // 分享链接；在微信上分享时，该链接的域名必须与企业某个应用的可信域名一致
          imgUrl: data.data.img
            ? data.data.img
            : "https://api.zq.ghatg.com/images/1677749724070.png", // 分享图标
          // enableIdTrans: 1, // 是否开启id转译，不填默认为0
          success: function () {
            // 用户确认分享后执行的回调函数
          },
          error: function (res) {
            if (res.errMsg.indexOf("no permission") > 0) {
              alert("未agentConfig");
            }
          },
          cancel: function () {
            // 用户取消分享后执行的回调函数
          },
        });

        // eslint-disable-next-line no-undef
        wx.onMenuShareTimeline({
          title: data.data.title, // 分享标题
          link: `https://m.ghatg.com/detail?id=${id}`, // 分享链接；在微信上分享时，该链接的域名必须与企业某个应用的可信域名一致
          imgUrl: data.data.img
            ? data.data.img
            : "https://api.zq.ghatg.com/images/1677749724070.png", // 分享图标
          // enableIdTrans: 1, // 是否开启id转译，不填默认为0
          success: function () {
            // 用户确认分享后执行的回调函数
          },
          error: function (res) {
            if (res.errMsg.indexOf("no permission") > 0) {
              alert("未agentConfig");
            }
          },
          cancel: function (res) {
            console.log(res);
            // 用户取消分享后执行的回调函数
          },
        });
      });
    },
    weixin(data, url, id) {
      var wx_nqy = this.$wx
      wx_nqy.config(data.wxConfig);
      console.log(data.wxConfig);
      console.log(url);
      console.log(wx_nqy.config);
      wx_nqy.ready(() => {
        // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
        //分享到朋友圈
        console.log("wx.ready");
        wx_nqy.updateTimelineShareData({
          title: data.data.title, // 分享时的标题
          desc: this.setText(data.data.content),
          link: `https://m.ghatg.com/detail?id=${id}`, // 分享时的链接
          imgUrl: data.data.img
            ? data.data.img
            : "https://api.zq.ghatg.com/images/1677749724070.png", // 分享时的图标
          success: function () {
            console.log("分享成功");
          },
          cancel: function () {
            console.log("取消分享");
          },
          fail: function (res) {
            console.log("fail" + JSON.stringify(res));
          },
          complete: function (res) {
            //接口调用完成时执行的回调函数，无论成功或失败都会执行。
            console.log("complete" + JSON.stringify(res));
          },
        });
        //分享给朋友
        wx_nqy.updateAppMessageShareData({
          title: data.data.title,
          desc: this.setText(data.data.content),
          link: `https://m.ghatg.com/detail?id=${id}`,
          imgUrl: data.data.img
            ? data.data.img
            : "https://api.zq.ghatg.com/images/1677749724070.png", //分享图标
          type: "",
          dataUrl: "",
          success: function () {
            console.log("分享成功");
          },
          cancel: function () {
            console.log("取消分享");
          },
          fail: function (res) {
            console.log("fail" + JSON.stringify(res));
          },
          complete: function (res) {
            //接口调用完成时执行的回调函数，无论成功或失败都会执行。
            console.log("complete" + JSON.stringify(res));
          },
        });
      });
    },
    // 提取纯文本
    setText(val) {
      if (val != null && val != "") {
        var re1 = new RegExp("<.+?>|&.+?;", "g"); //匹配html标签的正则表达式，"g"是搜索匹配多个符合的内容
        var msg = val.replace(re1, ""); //执行替换成空字符
        msg = msg.replace(/\s/g, ""); //去掉所有的空格（中文空格、英文空格都会被替换）
        msg = msg.replace(/[\r\n]/g, ""); //去掉所有的换行符
        // return msg.substr(0, 100); //获取文本文字内容的前100个字符
        return msg;
      } else return "";
    },
  },
};
</script>
<style>
.detail {
  padding: 0.3rem 0.2rem;
}

.detailHd {
  margin-bottom: 0.2rem;
  text-align: center;
}

.detailHdTit {
  font-size: 0.34rem;
  font-weight: bold;
  margin-bottom: 0.2rem;
}

.detailHdCont {
  color: #666;
}

.detailHdCont span {
  display: inline-block;
  margin: 0 0.2rem;
}

.detailWrap p {
  font-size: 0.28rem !important;
  line-height: 0.46rem;
}

.detailWrap img {
  max-width: 100% !important;
  height: auto;
}
</style>
